import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ConstantService} from "../constant.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CovidService {
  private readonly HOST = this.remoteHost.remoteHost();
  private readonly URL = this.HOST + 'covid/';
  private readonly URL_FULL = this.HOST + 'covid_full/';
  private readonly URL_COVID_NUMBER_VN_PER_DAY = this.remoteHost.host + 'covid/CovidNumberVietnamPerDay/';
  private readonly URL_COVID_NUMBER_LA_PER_DAY = this.remoteHost.host + 'covid/CovidNumberLaoPerDay/';
  private readonly URL_COVID_NUMBER_VN_PER_DAY_PER_PROVINCE = this.remoteHost.host + 'covid/CovidNumberVietnamPerProvincePerDay/';
  private readonly URL_COVID_NUMBER_LA_PER_DAY_PER_PROVINCE = this.remoteHost.host + 'covid/CovidNumberLaoPerProvincePerDay/';

  public today= new Date();
  public yesterdayString: any;
  public todayString: any;
  public sevenDaysBeforeString: any;
  public weekNumber:any;


  constructor(public httpClient: HttpClient, public remoteHost: ConstantService) {
    // this.today.setDate(this.today.getDate()-1)

    this.todayString = this.today.getFullYear().toString() + '-' + (this.today.getMonth()+1).toString() + '-' + (this.today.getDate()).toString();
    this.yesterdayString = this.today.getFullYear().toString() + '-' + (this.today.getMonth()+1).toString() + '-' + (this.today.getDate()-1).toString();
    this.sevenDaysBeforeString = this.today.getFullYear().toString() + '-' + (this.today.getMonth()+1).toString() + '-' + (this.today.getDate()-6).toString();

    let oneJan = new Date(this.today.getFullYear(),0,1);
    // console.log(oneJan);
    // let numberOfDays = Math.floor((this.today - oneJan) / (24 * 60 * 60 * 1000));
    // console.log(this.todayString)
    // console.log(this.yesterdayString)
    // console.log(this.sevenDaysBeforeString)
    // console.log(this.today.getFullYear())
    // console.log(this.today.getMonth()+1)
    // console.log(this.today.getDate())
    // console.log(this.today)
  }

  // 提取所有入库批次
  getCovidFullList(page = 1, pageSize = 2000, date = ''): Observable<any> {

    const params = new HttpParams()
      .set('page', page.toString())
      .set('date', date.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersNoToken});
  }

  // 提取所有入库批次
  getYesterdayStringCovidFullList(page = 1, pageSize = 2000): Observable<any> {

    const params = new HttpParams()
      .set('page', page.toString())
      .set('date', this.yesterdayString.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersNoToken});
  }


  // 提取所有入库批次
  getTodayStringCovidFullList(page = 1, pageSize = 2000): Observable<any> {

    const params = new HttpParams()
      .set('page', page.toString())
      .set('date', this.todayString.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersNoToken});
  }

  // 提取所有入库批次
  getThisMonthStringCovidFullList(page = 1, pageSize = 2000): Observable<any> {

    const params = new HttpParams()
      .set('year', this.today.getFullYear().toString())
      .set('month', (this.today.getMonth()+1).toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersNoToken});
  }

  // 最近6天的疫情数据
  getSevenDaysRecentCovidFullList(page = 1, pageSize = 2000, days=6): Observable<any> {

    let sevenDaysBeforeString = this.today.getFullYear().toString() + '-' + (this.today.getMonth()+1).toString() + '-' + (this.today.getDate()-days).toString();

    const params = new HttpParams()
      .set('dateStart', sevenDaysBeforeString)
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersNoToken});
  }

  // 最近6天的疫情数据
  getThisWeekCovidFullList(page = 1, pageSize = 2000, days=6): Observable<any> {

    let sevenDaysBeforeString = this.today.getFullYear().toString() + '-' + (this.today.getMonth()+1).toString() + '-' + (this.today.getDate()-days).toString();

    const params = new HttpParams()
      .set('dateStart', sevenDaysBeforeString)
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersNoToken});
  }

  // 最近6天的疫情数据
  getCovidNumberVietnamListPerDay(): Observable<any> {

    return this.httpClient.get<any>(this.URL_COVID_NUMBER_VN_PER_DAY, {headers: this.remoteHost.headersNoToken});
  }

  // 最近6天的疫情数据
  getCovidNumberLaoListPerDay(): Observable<any> {

    return this.httpClient.get<any>(this.URL_COVID_NUMBER_LA_PER_DAY, {headers: this.remoteHost.headersNoToken});
  }

  // 数据透视   日期 省份
  getCovidNumberVietnamListPerDayPerProvince(): Observable<any> {

    return this.httpClient.get<any>(this.URL_COVID_NUMBER_VN_PER_DAY_PER_PROVINCE, {headers: this.remoteHost.headersNoToken});
  }
  // 数据透视   日期 省份
  getCovidNumberLaoListPerDayPerProvince(): Observable<any> {

    return this.httpClient.get<any>(this.URL_COVID_NUMBER_LA_PER_DAY_PER_PROVINCE, {headers: this.remoteHost.headersNoToken});
  }


}
