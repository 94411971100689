import {Location} from '@angular/common';


export class BaseView {

    public urlCurrent: string;
    public urlBack: string;

    constructor(public location: Location) {}
    // 初始化函数
    initView() {
        this.setPathCurrent();
    }
    // 获取当前页面地址
    setPathCurrent() {
        // console.log('当前地址');
        this.urlCurrent = this.location.path();
    }
    // 获取上个页面的地址
    setPathBack(path: string) {
        // console.log('上一个地址');
        this.urlBack = path;
    }
}
