import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ConstantService} from "../constant.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InboundTruckOrderService {
  private readonly HOST = this.remoteHost.remoteHost();
  private readonly URL = this.HOST + 'inbound_truck_order/';
  private readonly URL_FULL = this.HOST + 'inbound_truck_order_full/';


  constructor(public httpClient: HttpClient, public remoteHost: ConstantService) { }

  // 提取所有入库批次
  getInboundTruckOrderList(page = 1, pageSize = 2000, ): Observable<any> {

    const params = new HttpParams()
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersIncludeToken});
  }
}
