import {NgModule, Optional, SkipSelf} from '@angular/core';
import {ServiceModule} from '../service/service.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {IonicModule} from '@ionic/angular';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AngularSvgIconModule} from "angular-svg-icon";
import {NgxEchartsModule} from "ngx-echarts";
// import {ServiceWorkerModule} from '@angular/service-worker';
// import {environment} from '../../environments/environment';

export function createTranslateLoader(http: HttpClient) {
// 此出的路径需要和第二步新建的文件夹保持一致
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    /*引入翻译插件*/
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ServiceModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parent: CoreModule,
  ) {
    if (parent) {
      throw new Error('模块已经存在，不能再次加载！');
    }
  }
}
