import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {RouterService} from "./service/router/router.service";
import {PrimeNGConfig} from "primeng/api";
import {RouterEntryPoint} from "@angular/compiler-cli/src/ngtsc/routing/src/route";
import {Platform} from "@ionic/angular";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  //gogotrans
  // title = 'gogotrans trade logistics';

  // 越南山东商会
  title = '越南中国商会山东企业联合会';

  // anh shin
  // title = 'anh shin supply chain';

  constructor(
    public translate: TranslateService,
    private primengConfig: PrimeNGConfig,
    public platform: Platform,
    private titleService: Title,
    private metaService: Meta
  ) {}


  ngOnInit(): void {
    this.getInfo();
    this.initTranslate();
    this.primengConfig.ripple = true;
    // console.log(this.platform.platforms());
    this.platform.resume.subscribe(async () => {
      alert('Resume event detected');
    });


    this.titleService.setTitle(this.title);

    // this.metaService.addTags([
    //   // gogotrans.com
    //   {name: 'keywords', content: 'gogotrans, trade, logistics, 物流，贸易, vietnam logistics, lao logistics, cambodia logistics'},
    //   {name: 'description', content: '越南，老挝，柬埔寨物流服务商。GOGOtrans is a professional logistics service provider in Vietnam, Laos and Cambodia. And provide trade agency services'},
    //
    //   // anhshin.com
    //   // {name: 'keywords', content: 'anh shin supply chain'},
    //   // {name: 'description', content: ' anh shin supply chain 安心供应链'},
    //
    //   {itemprop: 'name', content: 'GOGOtrans'},
    //   {itemprop: 'description', content: '越南，老挝，柬埔寨物流服务商。GOGOtrans is a professional logistics service provider in Vietnam, Laos and Cambodia. And provide trade agency services'},
    //   {itemprop: 'image', content: 'https://gogotrans.com/assets/icon/logo-name.svg'},
    //
    //   {property: 'og:url', content: 'https://gogotrans.com'},
    //   {property: 'og:type', content: 'website'},
    //   {property: 'og:title', content: 'gogotrans'},
    //   {property: 'og:description', content: '越南，老挝，柬埔寨物流服务商。GOGOtrans is a professional logistics service provider in Vietnam, Laos and Cambodia. And provide trade agency services'},
    //   {property: 'image', content: 'https://gogotrans.com/assets/icon/logo-name.svg'},
    //
    //   {name: 'twitter:card', content: 'summary_large_image'},
    //   {name: 'twitter:title', content: 'gogotrans'},
    //   {name: 'twitter:description', content: 'GOGOtrans'},
    //   {name: 'twitter:description', content: '越南，老挝，柬埔寨物流服务商。GOGOtrans is a professional logistics service provider in Vietnam, Laos and Cambodia. And provide trade agency services'},
    //   {name: 'twitter:image', content: 'https://gogotrans.com/assets/icon/logo-name.svg'},
    //
    //   // {name: 'description', content: 'GOGOtrans is a professional logistics service provider in Vietnam, Laos and Cambodia. And provide trade agency services'},
    //   {name: 'robots', content: 'index, follow'}
    // ]);

    this.metaService.addTags([
      // gogotrans.com
      {name: 'keywords', content: '越南中国商会山东企业联合会 越南山东商会 山东商会'},
      {name: 'description', content: '越南中国商会山东企业联合会 越南山东商会 山东商会'},

      {itemprop: 'name', content: '越南中国商会山东企业联合会 越南山东商会 山东商会'},
      {itemprop: 'description', content: '越南中国商会山东企业联合会 越南山东商会 山东商会'},
      {itemprop: 'image', content: 'https://vinasd.com/assets/vinasd/logo-slogan.svg'},

      {property: 'og:url', content: 'https://vinasd.com'},
      {property: 'og:type', content: 'website'},
      {property: 'og:title', content: '越南中国商会山东企业联合会 越南山东商会 山东商会'},
      {property: 'og:description', content: '越南中国商会山东企业联合会 越南山东商会 山东商会'},
      {property: 'image', content: 'https://vinasd.com/assets/vinasd/logo-slogan.svg'},

      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: '越南中国商会山东企业联合会'},
      {name: 'twitter:description', content: '越南中国商会山东企业联合会 越南山东商会 山东商会'},
      {name: 'twitter:description', content: '越南中国商会山东企业联合会 越南山东商会 山东商会'},
      {name: 'twitter:image', content: 'https://vinasd.com/assets/vinasd/logo-slogan.svg'},

      {name: 'robots', content: 'index, follow'}
    ]);
  }

  getInfo() {
    console.log(navigator.platform);
    console.log(navigator.geolocation);
  }

  initTranslate() {
    // 默认为越南语
    this.translate.setDefaultLang('vi');
    // 获取浏览器语言
    const browserLang = this.translate.getBrowserLang();
    // console.log(browserLang);

    if (browserLang) {
      // 判断中文简体，繁体
      if (browserLang === 'zh') {
        const browserCultureLang = this.translate.getBrowserCultureLang();
        // console.log(browserCultureLang);

        if (browserCultureLang.match(/-CN|CHS|Hans/i)) {
          this.translate.use('zh-cmn-Hans');
        } else if (browserCultureLang.match(/-TW|CHT|Hant/i)) {
          this.translate.use('zh-cmn-Hant');
        } else {
          this.translate.use('zh-cmn-Hans');
        }
      } else {
        // 根据支持的语言，进行判断， 不在支持范围内的设置为英语
        // translate.addLangs(['en', 'vi', 'ko']);
        this.translate.addLangs(['en', 'vi', 'ko', 'ja']);

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.use(browserLang.match(/en|ko|ja|vi/) ? browserLang : 'en');
      }
    } else {
      // 无法判断浏览器语言时候，这是为英语
      this.translate.use('en');
    }

  }

}
