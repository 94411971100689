import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ConstantService} from "../constant.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InboundService {
  private readonly HOST = this.remoteHost.remoteHost();
  private readonly URL = this.HOST + 'InboundList/';
  private readonly URL_FULL = this.HOST + 'InboundListFull/';
  private readonly URL_FULL_IN_STOCK = this.HOST + 'InStockInboundListFull/';


  constructor(public httpClient: HttpClient, public remoteHost: ConstantService) { }

  // 提取所有入库物料
  getInboundList(page = 1, pageSize = 20, ): Observable<any> {

    const params = new HttpParams()
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 提取某入库单数据
  getInboundListByInboundDeliveryOrderId(inboundOrderId:string, page = 1, pageSize = 2000, ): Observable<any> {

    const params = new HttpParams()
      .set('inboundOrder', inboundOrderId.toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersIncludeToken});
  }
  // 提取某入库单数据
  getInboundFullListByInboundOrderId(inboundOrderId:string, page = 1, pageSize = 2000, ): Observable<any> {

    const params = new HttpParams()
      .set('inboundOrder', inboundOrderId.toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL_FULL, {params, headers: this.remoteHost.headersIncludeToken});
  }
  // 提取入库单数据  根据采购物料 id
  getInboundListByPurchaseId(inboundOrder: string, purchasingList:string, storageNumber:string, page = 1, pageSize = 2000, ): Observable<any> {

    const params = new HttpParams()
      .set('inboundOrder', inboundOrder.toString())
      .set('purchasingList', purchasingList.toString())
      .set('storageNumber', storageNumber.toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 提取入库单数据  根据采购物料 id
  getInStockInboundListByPurchaseId(purchasingListId = '', page = 1, pageSize = 2000, ): Observable<any> {

    const params = new HttpParams()
      .set('purchasingListId', purchasingListId.toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL_FULL_IN_STOCK, {params, headers: this.remoteHost.headersIncludeToken});
  }


  // 提取指定入库物料信息
  getInboundById(id: any ): Observable<any> {

    const params = new HttpParams()
      .set('id', id.toString());

    return this.httpClient.get<any>(this.URL + id + '/', {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 提取指定入库物料信息
  getInboundFullById(id: any ): Observable<any> {

    const params = new HttpParams()
      .set('id', id.toString());

    return this.httpClient.get<any>(this.URL_FULL + id + '/', {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 添加商品类别
  addInbound(inbound: any): Observable<any> {
    return this.httpClient.post<any>(this.URL, inbound, {headers: this.remoteHost.headersIncludeToken});
  }

  // 更新商品入库
  patchInboundById(id: string, body): Observable<any> {
    return this.httpClient.patch<any>(this.URL + id + '/', body, {headers: this.remoteHost.headersIncludeToken} );
  }

}
