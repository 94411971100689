import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ConstantService} from "../constant.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PandaPackService {

  private readonly HOST = this.remoteHost.remoteHost();
  private readonly URL = this.HOST + 'pandaPack/';
  private readonly URL_FULL = this.HOST + 'pandaPackFull/';
  private readonly URL_PANDA_LIST = this.remoteHost.host + 'panda/PandaPackList/';
  private readonly URL_PANDA_LIST2021 = this.remoteHost.host + 'panda/PandaPackList2021/';
  private readonly URL_PANDA_LIST2023 = this.remoteHost.host + 'panda/PandaPackList2023/';


  constructor(public httpClient: HttpClient, public remoteHost: ConstantService) { }


  // 所有学校数据
  getPandaList(): Observable<any> {

    return this.httpClient.get<any>(this.URL_PANDA_LIST, {headers: this.remoteHost.headersNoToken});
  }

  getPandaList2021(page = 1, pageSize = 2000): Observable<any> {

    const params = new HttpParams()
      .set('pandaPackProject__id', 'bc148bb2-72ea-11ec-a6d3-0242ac130002')
      .set('page', page.toString())
      .set('page_size', pageSize.toString());



    return this.httpClient.get<any>(this.URL_PANDA_LIST2021, {params, headers: this.remoteHost.headersNoToken});
  }

  getPandaList2023(page = 1, pageSize = 2000): Observable<any> {

    const params = new HttpParams()
      .set('pandaPackProject__id', 'b8546ef2-e0bc-11ed-a21f-0242ac130004')
      .set('page', page.toString())
      .set('page_size', pageSize.toString());



    return this.httpClient.get<any>(this.URL_PANDA_LIST2023, {params, headers: this.remoteHost.headersNoToken});
  }
}
