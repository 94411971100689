import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {RouterService} from "../../service/router/router.service";
import {LoginViewService} from "../../service/viewService/login-view.service";

@Component({
  selector: 'app-sider-menu',
  templateUrl: './sider-menu.component.html',
  styleUrls: ['./sider-menu.component.scss'],
})
export class SiderMenuComponent implements OnInit {
  // public appPages = [
  //   { title: 'supplier', url: this.routerService.supplier, icon: 'people-circle' },
  //   { title: 'inbound', url: this.routerService.inbound, icon: 'arrow-down' },
  //   { title: 'outbound', url: this.routerService.inbound, icon: 'arrow-up' },
  //   { title: 'truck', url: this.routerService.truck, icon: 'car' },
  //   { title: 'warehouse', url: this.routerService.warehouse, icon: 'server' },
  //   { title: 'client', url: this.routerService.client, icon: 'people' },
  //   { title: 'inventory', url: this.routerService.inventory, icon: 'git-compare' },
  //   { title: 'receiver', url: this.routerService.receiver, icon: 'arrow-forward' },
  //
  // ];
  // private home = this.translate.instant('menu.home');
  //
  // public tabPages = [
  //   { title: this.home, url: this.routerService.home, icon: 'home' },
  //   { title: this.translate.instant('menu.settings'), url: this.routerService.setting, icon: 'settings' },
  //   { title: this.translate.instant('menu.help'), url: this.routerService.help, icon: 'help' },
  //
  // ];

  constructor(
    public translate: TranslateService,
    public routerService: RouterService,
    public loginViewService: LoginViewService,

  ) { }

  ngOnInit() {}
  toPage(url: string) {
    this.routerService.toUrl(url).then();
  }

}
