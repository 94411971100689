import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpHeaders} from '@angular/common/http';
import {ToastController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {StorageService} from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  // public host = '';  // 同局域网内
  // public host = 'http://111.231.101.150:8801/';
  // public host = 'http://192.168.1.6:8003/';
  public domain = environment.remote_domain;
  public host = 'https://manage.gogotrans.com/';
  public hostAdmin = 'https://admin.gogotrans.com/';
  public name = 'api/';
  public token = '';

  public headersIncludeToken: any;
  public headersFilesIncludeToken: any;

  public headersNoToken = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  );

  public url = this.host + this.name;
  public urlAdmin = this.hostAdmin + this.name;

  constructor(
    private toastCtrl: ToastController,
    private storage: Storage,
    public storageService: StorageService,
  ) {
    this.storage.create().then();

    this.storageService.getToken().then(value => {
        this.setHeadersIncludeToken(value);
    });

    this.headersFilesIncludeToken = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        enctype: 'multipart/form-data',
        // Authorization: token ? 'JWT ' + token : '',
      }
    )
  }

  public remoteHost() {
    return this.url;
  }
  public remoteHostAdmin() {
    return this.urlAdmin;
  }

  setHeadersIncludeToken(token) {
    this.headersIncludeToken = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token ? 'JWT ' + token : '',
      }
    );
    this.headersFilesIncludeToken = new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        // enctype: 'multipart/form-data',
        Authorization: token ? 'JWT ' + token : '',
      }
    );
  }

  async presentToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 1000
    });
    toast.present();
  }

}
