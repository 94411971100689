import {ModuleWithProviders, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {ConstantService} from './constant.service';

import {UserService} from './users/user.service';
import {AuthService} from './users/auth.service';
import {UserAddressService} from './users/user-address.service';
import {SupplierService} from './supplier/supplier.service';
import {RouterService} from './router/router.service';
import {StorageService} from './storage.service';
import {PurchaseOrderService} from "./inbound/purchase-order.service";
import {ClientService} from "./client/client.service";
import {ExceljsService} from "./excel/exceljs.service";
import {ExportService} from "./excel/export.service";
import {ReceiverService} from "./receiver/receiver.service";
import {SalesOrderService} from "./outbound/sales-order.service";
import {OutboundOrderService} from "./outbound/outbound-order.service";
import {WarehouseService} from "./warehouse/warehouse.service";
import {TruckService} from "./truck/truck.service";
import {OutboundTruckOrderService} from "./truck/outbound-truck-order.service";
import {InboundTruckOrderService} from "./truck/inbound-truck-order.service";
import {InboundService} from "./inbound/inbound.service";
import {OutboundService} from "./outbound/outbound.service";
import {AdministrativeService} from "./administrative/administrative.service";
import {CovidService} from "./covid/covid.service";
import {PandaPackService} from "./pandaPack/panda-pack.service";
import {GoodsSkuService} from "./products/goods-sku.service";


@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
  ]
})
export class ServiceModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: ServiceModule,
      providers: [
        ConstantService,
        RouterService,
        StorageService,

        UserService,
        UserAddressService,
        AuthService,


        SupplierService,

        PurchaseOrderService,
        ClientService,
        ExportService,

        ReceiverService,
        SalesOrderService,
        OutboundOrderService,
        WarehouseService,
        TruckService,
        OutboundTruckOrderService,
        InboundTruckOrderService,
        InboundService,
        OutboundService,

        AdministrativeService,
        CovidService,
        PandaPackService,
        GoodsSkuService,


      ]
    };
  }
}
