import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConstantService} from '../constant.service';
import {Observable} from 'rxjs';
import {UserAddress} from '../../interface/users/userAddress';

@Injectable({
    providedIn: 'root'
})
export class UserAddressService {

    private readonly HOST = this.remoteHost.remoteHost();
    // private readonly URL_LIST = this.HOST + 'goods_info_list/';
    private readonly URL = this.HOST + 'users_address/';
    // private readonly URL_INCLUDE_SKU_IMAGE = this.HOST + 'goods_info_include_sku_image/';

    constructor(
        public httpClient: HttpClient,
        public remoteHost: ConstantService,
    ) {

    }

    // 提取客户购物车信息
    getUserAddressList(userID = '', page = 1, pageSize = 100): Observable<any> {
        const params = new HttpParams()
            .set('user', userID.toString())
            .set('page', page.toString())
            .set('page_size', pageSize.toString());

        return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersIncludeToken});
    }

    // 添加收货地址
    addBasicUserAddress(userAddress: UserAddress): Observable<any> {
        return this.httpClient.post<any>(this.URL, userAddress, {headers: this.remoteHost.headersIncludeToken});
    }

    // 更新商品类别
    patchUserAddressById(id: string, body): Observable<any> {
        return this.httpClient.patch<any>(this.URL + id + '/', body, {headers: this.remoteHost.headersIncludeToken});
    }

    // 删除收货地址
    deleteBasicUserAddress(id: string): Observable<any> {
        return this.httpClient.delete<any>(this.URL + id + '/', {headers: this.remoteHost.headersIncludeToken});
    }
}
