import { Injectable } from '@angular/core';
import {CanActivate} from '@angular/router';
import {Storage} from '@ionic/storage';
import {LoginViewService} from "../service/viewService/login-view.service";
import {RouterService} from "../service/router/router.service";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
      private storage: Storage,
      private loginViewService: LoginViewService,
      private routerService: RouterService
  ) {}

  canActivate() {
    // 从本地提取
    return this.storage.get('userInfo').then(value => {
      this.loginViewService.userInfo = value;
      if (this.loginViewService.userInfo) {
        // 用户登录
        return true;
      } else {
        // 用户未登录
        this.routerService.toLogin().then();
        return false;
      }
    });



  }

}
