import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ConstantService} from "../constant.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OutboundService {
  private readonly HOST = this.remoteHost.remoteHost();
  private readonly URL = this.HOST + 'OutboundList/';
  private readonly URL_FULL = this.HOST + 'OutboundListFull/';


  constructor(public httpClient: HttpClient, public remoteHost: ConstantService) { }

  // 提取所有入库物料
  getOutboundList(page = 1, pageSize = 20, ): Observable<any> {

    const params = new HttpParams()
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 提取某入库单数据
  getOutboundListByOutboundOrderId(outboundOrderId:string, page = 1, pageSize = 2000, ): Observable<any> {

    const params = new HttpParams()
      .set('outboundOrder', outboundOrderId.toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersIncludeToken});
  }
  // 提取某入库单数据
  getOutboundFullListByOutboundOrderId(outboundOrderId:string, page = 1, pageSize = 2000, ): Observable<any> {

    const params = new HttpParams()
      .set('outboundOrder', outboundOrderId.toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL_FULL, {params, headers: this.remoteHost.headersIncludeToken});
  }

  getOutboundListByOutboundOrderIdAndInboundId(outboundOrderId: string, inboundId: string, page = 1, pageSize = 2000, ) {
    const params = new HttpParams()
      .set('outboundOrder', outboundOrderId.toString())
      .set('inbound', inboundId.toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL_FULL, {params, headers: this.remoteHost.headersIncludeToken});

  }

  // 提取入库单数据  根据采购物料 id
  getOutboundListBySalesId(outboundOrder: string, salesList:string, page = 1, pageSize = 2000, ): Observable<any> {

    const params = new HttpParams()
      .set('outboundOrder', outboundOrder.toString())
      .set('salesList', salesList.toString())
      .set('page', page.toString())
      .set('page_size', pageSize.toString());

    return this.httpClient.get<any>(this.URL, {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 提取指定入库物料信息
  getOutboundById(id: any ): Observable<any> {

    const params = new HttpParams()
      .set('id', id.toString());

    return this.httpClient.get<any>(this.URL + id + '/', {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 提取指定入库物料信息
  getOutboundFullById(id: any ): Observable<any> {

    const params = new HttpParams()
      .set('id', id.toString());

    return this.httpClient.get<any>(this.URL_FULL + id + '/', {params, headers: this.remoteHost.headersIncludeToken});
  }

  // 添加商品类别
  addOutbound(inbound: any): Observable<any> {
    return this.httpClient.post<any>(this.URL, inbound, {headers: this.remoteHost.headersIncludeToken});
  }

  // 更新商品入库
  patchOutboundById(id: string, body): Observable<any> {
    return this.httpClient.patch<any>(this.URL + id + '/', body, {headers: this.remoteHost.headersIncludeToken} );
  }

}
