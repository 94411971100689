import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConstantService} from '../constant.service';
import {Observable} from 'rxjs';
import {UserLogin} from '../../interface/users/userLogin';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // private readonly HOST = this.remoteHost.remoteHost();
  private readonly HOST = this.remoteHost.remoteHost();
  // private readonly URL_LIST = this.HOST + 'words_list/';
  private readonly URL_CREATE_JWT =  this.HOST + 'auth-jwt/';
  private readonly URL_REFRESH_JWT =  this.HOST + 'auth-jwt-refresh/';
  private readonly URL_VERIFY_JWT =  this.HOST + 'auth-jwt-verify/';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  constructor(
    public httpClient: HttpClient,
    public remoteHost: ConstantService,
  ) { }

  createToken(user: UserLogin): Observable<any> {
    return this.httpClient.post<any>(this.URL_CREATE_JWT, user, {headers: this.headers});
  }

  updateToken(token: string): Observable<string> {
    return this.httpClient.post<string>(this.URL_REFRESH_JWT, token, {headers: this.headers});
  }

  verifyToken(token: string): Observable<string> {
    return this.httpClient.post<string>(this.URL_VERIFY_JWT, token, {headers: this.headers});
  }

}
