import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import {QRCodeModule} from "angularx-qrcode";
import {CoreModule} from "./core/core.module";
import {TranslateModule} from "@ngx-translate/core";
import {SiderMenuComponent} from "./components/sider-menu/sider-menu.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import { IonicStorageModule } from '@ionic/storage-angular';

@NgModule({
  declarations: [AppComponent, SiderMenuComponent],
  entryComponents: [],
  imports: [
    CoreModule,
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    QRCodeModule,
    TranslateModule,
  ],
  providers: [
    BarcodeScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
  bootstrap: [AppComponent],
})
export class AppModule {}
