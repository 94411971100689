import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConstantService} from '../constant.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private readonly HOST = this.constantService.remoteHost();
    private readonly URL = this.HOST + 'users/';

    constructor(
        private httpClient: HttpClient,
        private constantService: ConstantService,
    ) {}

    // 提取用户信息
    getUser(page = 1, pageSize = 20, username = '', ordering= '-addTime'): Observable<any> {

        const params = new HttpParams()
            .set('username', username.toString())
            .set('ordering', ordering ? ordering : '-addTime')
            .set('page', page.toString())
            .set('page_size', pageSize.toString());

        return this.httpClient.get<any>(this.URL, {params, headers: this.constantService.headersIncludeToken});
    }

    getUserById(id: string): Observable<any> {
      const params = new HttpParams()
        .set('id', id.toString());

      return this.httpClient.get<any>(this.URL + id, {params, headers: this.constantService.headersIncludeToken});
    }

    // 更新
    putchUserById(id: string, body): Observable<any> {
        return this.httpClient.patch<any>(this.URL + id, body);
    }


    createUser(user: any): Observable<any> {
        return this.httpClient.post<any>(this.URL, user, );
    }

    deleteUser(id: string): Observable<any> {
        return this.httpClient.delete<any>(this.URL + id, {headers: this.constantService.headersIncludeToken});
    }

}
