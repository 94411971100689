import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {LoginGuard} from "./guards/login.guard";

const routes: Routes = [
  { path: '', redirectTo: 'home1', pathMatch: 'full' },
  {
    path: 'home1',
    loadChildren: () => import('./pages/home1/home1.module').then( m => m.Home1PageModule)
  },
  {
    path: 'tabs',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'supplier',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/supplier/supplier.module').then( m => m.SupplierPageModule)
  },
  {
    path: 'client',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'truck',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/truck/truck.module').then( m => m.TruckPageModule)
  },
  {
    path: 'receiver',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/receiver/receiver.module').then( m => m.ReceiverPageModule)
  },
  {
    path: 'warehouse',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/warehouse/warehouse.module').then( m => m.WarehousePageModule)
  },
  {
    path: 'inventory',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inventory/inventory.module').then( m => m.InventoryPageModule)
  },
  {
    path: 'outbound',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/outbound/outbound.module').then( m => m.OutboundPageModule)
  },
  {
    path: 'help',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'language',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/language/language.module').then( m => m.LanguagePageModule)
  },

  {
    path: 'stock',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/stock/stock.module').then( m => m.StockPageModule)
  },
  {
    path: 'inbound-import',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inbound-import/inbound-import.module').then( m => m.InboundImportPageModule)
  },
  {
    path: 'outbound-goods-detail',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/outbound-goods-detail/outbound-goods-detail.module').then( m => m.OutboundGoodsDetailPageModule)
  },
  {
    path: 'outbound-goods-detail/:id',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/outbound-goods-detail/outbound-goods-detail.module').then( m => m.OutboundGoodsDetailPageModule)
  },
  {
    path: 'task-tabs',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/task-tabs/task-tabs.module').then( m => m.TaskTabsPageModule)
  },
  {
    path: 'truck-order',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/truck-order/truck-order.module').then( m => m.TruckOrderPageModule)
  },
  {
    path: 'inbound-add',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inbound-add/inbound-add.module').then( m => m.InboundAddPageModule)
  },
  {
    path: 'inbound-action',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inbound-action/inbound-action.module').then( m => m.InboundActionPageModule)
  },
  {
    path: 'sales-order-list',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/sales-order-list/sales-order-list.module').then( m => m.SalesOrderListPageModule)
  },
  {
    path: 'purchase-order-list',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/purchase-order-list/purchase-order-list.module').then( m => m.PurchaseOrderListPageModule)
  },
  {
    path: 'purchase-order-add',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/purchase-order-add/purchase-order-add.module').then( m => m.PurchaseOrderAddPageModule)
  },
  {
    path: 'purchase-order-detail',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/purchase-order-detail/purchase-order-detail.module').then( m => m.PurchaseOrderDetailPageModule)
  },
  {
    path: 'purchase-list',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/purchase-list/purchase-list.module').then( m => m.PurchaseListPageModule)
  },
  {
    path: 'purchase-add',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/purchase-add/purchase-add.module').then( m => m.PurchaseAddPageModule)
  },
  {
    path: 'purchase-import',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/purchase-import/purchase-import.module').then( m => m.PurchaseImportPageModule)
  },
  {
    path: 'inbound-order-list',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inbound-order-list/inbound-order-list.module').then( m => m.InboundOrderListPageModule)
  },
  {
    path: 'inbound-order-add',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inbound-order-add/inbound-order-add.module').then( m => m.InboundOrderAddPageModule)
  },
  {
    path: 'inbound-order-detail',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inbound-order-detail/inbound-order-detail.module').then( m => m.InboundOrderDetailPageModule)
  },
  {
    path: 'inbound-list',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inbound-list/inbound-list.module').then( m => m.InboundListPageModule)
  },
  {
    path: 'inbound-detail',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inbound-detail/inbound-detail.module').then( m => m.InboundDetailPageModule)
  },
  {
    path: 'inbound-detail/:id',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inbound-detail/inbound-detail.module').then( m => m.InboundDetailPageModule)
  },
  {
    path: 'inbound-action-add',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/inbound-action-add/inbound-action-add.module').then( m => m.InboundActionAddPageModule)
  },
  {
    path: 'sales-order-add',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/sales-order-add/sales-order-add.module').then( m => m.SalesOrderAddPageModule)
  },
  {
    path: 'outbound-order-list',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/outbound-order-list/outbound-order-list.module').then( m => m.OutboundOrderListPageModule)
  },
  {
    path: 'outbound-list',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/outbound-list/outbound-list.module').then( m => m.OutboundListPageModule)
  },
  {
    path: 'sales-list',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/sales-list/sales-list.module').then( m => m.SalesListPageModule)
  },
  {
    path: 'sales-add',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/sales-add/sales-add.module').then( m => m.SalesAddPageModule)
  },
  {
    path: 'sales-import',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/sales-import/sales-import.module').then( m => m.SalesImportPageModule)
  },
  {
    path: 'sales-order-detail',
    canActivate: [LoginGuard],

    loadChildren: () => import('./pages/sales-order-detail/sales-order-detail.module').then( m => m.SalesOrderDetailPageModule)
  },
  {
    path: 'outbound-order-add',
    loadChildren: () => import('./pages/outbound-order-add/outbound-order-add.module').then( m => m.OutboundOrderAddPageModule)
  },
  {
    path: 'outbound-order-detail',
    loadChildren: () => import('./pages/outbound-order-detail/outbound-order-detail.module').then( m => m.OutboundOrderDetailPageModule)
  },
  {
    path: 'outbound-action-list',
    loadChildren: () => import('./pages/outbound-action-list/outbound-action-list.module').then( m => m.OutboundActionListPageModule)
  },
  {
    path: 'sales-select-action-list',
    loadChildren: () => import('./pages/sales-select-action-list/sales-select-action-list.module').then( m => m.SalesSelectActionListPageModule)
  },
  {
    path: 'sales-select-action-add',
    loadChildren: () => import('./pages/sales-select-action-add/sales-select-action-add.module').then( m => m.SalesSelectActionAddPageModule)
  },
  {
    path: 'outbound-action-add',
    loadChildren: () => import('./pages/outbound-action-add/outbound-action-add.module').then( m => m.OutboundActionAddPageModule)
  },
  {
    path: 'outbound-action-location',
    loadChildren: () => import('./pages/outbound-action-location/outbound-action-location.module').then( m => m.OutboundActionLocationPageModule)
  },
  {
    path: 'outbound-detail',
    loadChildren: () => import('./pages/outbound-detail/outbound-detail.module').then( m => m.OutboundDetailPageModule)
  },
  {
    path: 'receiver-list',
    loadChildren: () => import('./pages/receiver-list/receiver-list.module').then( m => m.ReceiverListPageModule)
  },
  {
    path: 'receiver-map',
    loadChildren: () => import('./pages/receiver-map/receiver-map.module').then( m => m.ReceiverMapPageModule)
  },
  {
    path: 'warehouse-map',
    loadChildren: () => import('./pages/warehouse-map/warehouse-map.module').then( m => m.WarehouseMapPageModule)
  },
  {
    path: 'receiver-import',
    loadChildren: () => import('./pages/receiver-import/receiver-import.module').then( m => m.ReceiverImportPageModule)
  },
  {
    path: 'outbound-truck-order-list',
    loadChildren: () => import('./pages/outbound-truck-order-list/outbound-truck-order-list.module').then( m => m.OutboundTruckOrderListPageModule)
  },
  {
    path: 'administrative-map',
    loadChildren: () => import('./pages/administrative-map/administrative-map.module').then( m => m.AdministrativeMapPageModule)
  },
  {
    path: 'covid-map',
    loadChildren: () => import('./pages/covid-map/covid-map.module').then( m => m.CovidMapPageModule)
  },
  {
    path: 'home1',
    loadChildren: () => import('./pages/home1/home1.module').then( m => m.Home1PageModule)
  },
  {
    path: 'covid-lao-map',
    loadChildren: () => import('./pages/covid-lao-map/covid-lao-map.module').then( m => m.CovidLaoMapPageModule)
  },
  {
    path: 'covid',
    loadChildren: () => import('./pages/covid-report/covid-report.module').then( m => m.CovidReportPageModule)
  },
  {
    path: 'panda-map',
    loadChildren: () => import('./pages/panda-map/panda-map.module').then( m => m.PandaMapPageModule)
  },
  {
    path: 'panda-map-2021',
    loadChildren: () => import('./pages/panda-map/panda-map.module').then( m => m.PandaMapPageModule)
  },

  {
    path: 'music',
    loadChildren: () => import('./pages/music/music.module').then( m => m.MusicPageModule)
  },
  {
    path: 'pinyin',
    loadChildren: () => import('./pages/pinyin/pinyin.module').then( m => m.PinyinPageModule)
  },


  {
    path: 'chinese',
    loadChildren: () => import('./pages/chinese/chinese.module').then( m => m.ChinesePageModule)
  },

  {
    path: 'beer',
    loadChildren: () => import('./pages/beer/beer.module').then( m => m.BeerPageModule)
  },

  {
    path: 'sku-show-list',
    loadChildren: () => import('./pages/sku-show-list/sku-show-list.module').then(m => m.SkuShowListPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'vinasd-home1',
    loadChildren: () => import('./pages/vinasd/vinasd-home1/vinasd-home1.module').then( m => m.VinasdHome1PageModule)
  },
  {
    path: 'panda-map-2023',
    loadChildren: () => import('./pages/pandamap2023/pandamap2023.module').then( m => m.Pandamap2023PageModule)
  },
  { path: '**', redirectTo: 'home1' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
