import { Injectable } from '@angular/core';
import {Storage} from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public readonly token = 'token';
  public readonly userInfo = 'userInfo';

  constructor(private storage: Storage) {
    this.storage.create().then();
  }

  setToken(token) {return this.storage.set(this.token, token); }   // 存储token
  getToken() {return this.storage.get(this.token); }     // 获取token
  removeToken() {return this.storage.remove(this.token); }   // 删除token


  setUserInfo(value) {return this.storage.set(this.userInfo, value); }   // 存储用户信息
  getUserInfo() {return this.storage.get(this.userInfo); }   // 获取用户信息
  removeUserInfo() {return this.storage.remove(this.userInfo); }   // 删除用户信息

}
