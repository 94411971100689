import {Injectable} from '@angular/core';
import {User} from '../../interface/users/user';
import {UserLogin} from '../../interface/users/userLogin';
import {HttpHeaders} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {BaseView} from './base-view';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ConstantService} from "../constant.service";
import {AuthService} from "../users/auth.service";
import {RouterService} from "../router/router.service";
import {UserService} from "../users/user.service";

@Injectable({
    providedIn: 'root'
})
export class LoginViewService extends BaseView {
    // 用户信息
    public userInfo: User;
    // 用户登录信息
    public userLogin: UserLogin = {
        password: '',
        username: '',
    };

    constructor(private constantService: ConstantService,
                private storage: Storage,
                private authService: AuthService,
                private routerService: RouterService,
                private userService: UserService,
                public location: Location,
                public translate: TranslateService,

    ) {
        super(location);
    }
    // 存储用户信息
    setUserInfo(user: User) {
        // 本地存储
        this.storage.set('userInfo', user).then();
        // 服务存储
        this.userInfo = user;
        // this.routerService.toPersonal().then();
    }

    // 登录
    logIn() {

        // 判断用户名是否为空
        if (this.userLogin.username === '') {
            this.constantService.presentToast(this.translate.instant('login.inputUsername')).then();
            return;
        }
        // 判断密码是否为空
        if (this.userLogin.password === '') {
            this.constantService.presentToast(this.translate.instant('login.inputPassword')).then();
            return;
        }
        // 登录数据清洗
        // 去掉两端空格
        // 大写转小写
        this.userLogin.username = this.userLogin.username.trim().toLowerCase();
        this.userLogin.password = this.userLogin.password.trim();


        // 登录， 获取jwt token
        this.authService.createToken(this.userLogin).subscribe(
            value => {
              // console.log(value.token);
                // 存储token
                this.storage.set('token', value.token).then();
                // 设置headers
                this.constantService.setHeadersIncludeToken(value.token);

                // 查询出用户信息
                this.userService.getUser(1, 1, this.userLogin.username).subscribe(
                    value1 => {
                        // 更新用户信息
                        this.setUserInfo(value1.results[0]);
                        // 返回首页
                        this.routerService.toHome().then();
                    }
                );
            },
            () => {
                this.constantService.presentToast(this.translate.instant('login.userOrPasswordNotRight')).then();
            },
            () => {
                this.userLogin.password = '';
            }
        );

    }
    // 退出登录
    logOut() {
        // 移除本地用户信息
        this.storage.remove('userInfo').then();
        // 移除服务用户信息
        this.userInfo = null;
        // 移除token
        this.storage.remove('token').then();
        // 设置头部
        this.constantService.headersIncludeToken = new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        );
        this.routerService.toLogin().then();

    }
    // 登录
    // 返回到用户页面
    goBack() {this.routerService.toHome().then(); }
    // 返回到注册页面
    toRegister()  {this.routerService.toRegister().then(); }
}
