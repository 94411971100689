import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  public login = '/login';  // 登陆
  public register = '/register';  // 注册
  public language = '/language';  // 语言
  // tabs
  public home = '/tabs/home'; // 后台用户列表
  public home1 = '/home1'; // 后台用户列表
  public vinasd_home1 = '/vinasd_home1'; // 后台用户列表
  public search = '/tabs/tab2'; // 后台用户添加
  public setting = '/tabs/setting'; // 后台用户编辑

  // sidermenu

  public supplier = '/supplier'; // 供应商
  public truck = '/truck'; // 拖车
  public truckOrder = '/truck-order'; // 拖车
  public outboundTruckOrderList = '/outbound-truck-order-list'; // 出库拖车订单列表
  public warehouse = '/warehouse'; // 仓库
  public warehouseMap = '/warehouse-map';
  public client = '/client'; // 客户
  public inventory = '/inventory'; // 盘点
  public receiver = '/receiver'; // 收货人
  public receiverMap = '/receiver-map'; // 收货人
  public receiverList = '/receiver-list'; // 收货人
  public help = '/help'; // 帮助
  public dashboard = '/dashboard'; // 帮助
  // 入库
  public inboundList = '/inbound-list'; // 入库
  public inboundAction = '/inbound-action'; // 入库动作
  public inboundAdd = '/inbound-add'; // 入库
  public purchaseList = '/purchase-list'; // 采购清单
  public purchaseAdd = '/purchase-add'; // 采购清单 添加
  public inboundDetail = '/inbound-detail'; // 入库货物详情
  public inboundBatch = '/inbound-batch'; // 入库批次
  public purchaseOrderList = '/purchase-order-list'; // 入库批次

  public purchaseOrderAdd = '/purchase-order-add'; // 采购单增加
  public purchaseOrderDetail = '/purchase-order-detail'; // 采购单详情
  public inboundOrderList = '/inbound-order-list'; // 入库批次
  public inboundOrderAdd = '/inbound-order-add'; // 入库批次
  public inboundOrderDetail = '/inbound-order-detail'; // 入库批次
  public inboundImport = '/inbound-import'; // 导入入库数据

  // 出库
  public salesOrderList = '/sales-order-list'; // sales order list
  public salesOrderAdd = '/sales-order-add'; // sales order add
  public salesOrderDetail =  '/sales-order-detail'; //sales-order-detail
  public salesList = '/sales-list';
  public salesSelectActionList = '/sales-select-action-list';

  public outboundOrderList = '/outbound-order-list'; // sales order add
  public outboundOrderAdd = '/outbound-order-add'; // 出库单  增加
  public outboundOrderDetail = '/outbound-order-detail'; // 出库单  增加
  public outboundList = '/outbound-list'; // 出库物料
  public outboundDetail = '/outbound-detail'; // 出库物料

  public outboundActionList = '/outbound-action-list'; // 入库动作
  public outboundActionLocation = '/outbound-action-location'

  //库存
  public stock = '/stock'; // 库存
  private salesAdd = '/sales-add';
  private salesListAdd = '/sales-list-add';

  public administrativeMap = 'administrative-map';
  public covidMap = 'covid-map';
  public covidLaoMap = 'covid-lao-map';
  public covidReport = 'covid';
  public pandaMap = 'panda-map';
  public pandaMap2023 = 'panda-map-2023';
  public pandaMap2021 = 'panda-map-2021';

  public music = '/music';
  public pinYin = '/pinyin';
  public chinese = '/chinese';
  public beer = '/beer';
  public skuShowList = '/sku-show-list';


  constructor(private router: Router, private location: Location) {
  }


  toUrl(url: string) {
    return this.router.navigate([url]).then();
  }  // 通用导航

  toLogin() {
    return this.router.navigate([this.login]).then();
  }  // 导航到登陆页面
  toRegister() {
    return this.router.navigate([this.register]).then();
  }  // 导航到注册页面
  toMusic() {
    return this.router.navigate([this.music]).then();
  }  // 导航到注册页面
  toSkuShowList() {
    return this.router.navigate([this.skuShowList]).then();
  }  // 导航到注册页面
  toPinYin() {
    return this.router.navigate([this.pinYin]).then();
  }  // 导航到注册页面

  // TABS
  toHome() {
    return this.router.navigate([this.home]).then();
  }  // 导航到主页页面
  toHome1() {
    return this.router.navigate([this.home1]).then();
  }  // 导航到主页页面
  toVinasdHome1(){
    return this.router.navigate([this.vinasd_home1]).then();
  }
  toSetting() {
    return this.router.navigate([this.setting]).then();
  }  // 导航到设置页面

  // 入库
  toInboundList() {
    return this.router.navigate([this.inboundList]).then();
  }  // 导航到入库列表页面
  toInboundAdd() {
    return this.router.navigate([this.inboundAdd]).then();
  }  // 导航到入库添加页面
  toInboundAddByInboundOrderIdAndWarehouseId(inboundOrderId, warehouseId) {
    return this.router.navigate([this.inboundAdd], {
      queryParams: {
        inboundOrderId: inboundOrderId,
        warehouseId: warehouseId
      }
    }).then();
  }  // 导航到入库添加页面
  toInboundAction() {
    return this.router.navigate([this.inboundAction]).then();
  }  // 导航到入库 action
  toInboundActionByPurchaseIdInboundOrderIdAndWarehouseId(poId, ioId, whId) {
    return this.router.navigate(
      [this.inboundAction],
      {queryParams: {purchaseOrderId: poId, inboundOrderId: ioId, warehouseId: whId}}
    ).then();
  }  // 导航到入库 action

  toInboundListByInboundOrderId(inboundOrderId: string) {
    return this.router.navigate([this.inboundList], {
      queryParams: {
        inboundOrderId: inboundOrderId,
      }
    }).then();
  }  // 导航到 入库单 列表页面
  toInboundImport() {
    return this.router.navigate([this.inboundImport]).then();
  }  // 导航到入库导入列表页面
  toInboundDetail() {
    return this.router.navigate([this.inboundDetail]).then();
  }  // 导航到货物详情页面

  toInboundDetailById(id: string) {
    return this.router.navigate([this.inboundDetail], {queryParams: {id: id}}).then();
  }  // 导航到 入库单 列表页面
  toInboundDetailWithCode(id: string) {
    return this.router.navigate([this.inboundDetail, id.toString()]).then();
  }  // 导航到后台用户编辑页面

  toPurchaseOrderList() {
    return this.router.navigate([this.purchaseOrderList]).then();
  }  // 导航到 入库单 列表页面
  toPurchaseOrderAdd() {
    return this.router.navigate([this.purchaseOrderAdd]).then();
  }  // 导航到 入库单 列表页面
  toPurchaseList() {
    return this.router.navigate([this.purchaseList]).then();
  }  // 导航到 入库单 列表页面
  toPurchaseAddByPurchaseOrderId(purchaseOrderId: string) {
    return this.router.navigate([this.purchaseAdd], {queryParams: {inboundBatchId: purchaseOrderId}}).then();
  }  // 导航到 入库总单 添加页面
  toPurchaseListByPurchaseOrderId(purchaseOrderId: string) {
    return this.router.navigate([this.purchaseList], {queryParams: {inboundBatchId: purchaseOrderId}}).then();
  }  // 导航到 入库单 列表页面
  toPurchaseOrderDetail(id: string) {
    return this.router.navigate([this.purchaseOrderDetail], {queryParams: {id: id}}).then();
  }  // 导航到 入库单 列表页面
  toInboundOrderList() {
    return this.router.navigate([this.inboundOrderList]).then();
  }  // 导航到 入库单 列表页面
  toInboundOrderAdd() {
    return this.router.navigate([this.inboundOrderAdd]).then();
  }  // 导航到 入库单 列表页面
  toInboundOrderAddByPurchaseOrderId(inboundBatchId: string) {
    return this.router.navigate([this.inboundOrderAdd], {queryParams: {inboundBatchId: inboundBatchId}}).then();
  }  // 导航到 入库单 列表页面
  toInboundOrderListByPurchaseOrderId(inboundBatchId: string) {
    return this.router.navigate([this.inboundOrderList], {queryParams: {inboundBatchId: inboundBatchId}}).then();
  }  // 导航到 入库单 列表页面
  toInboundOrderDetail(id: string) {
    return this.router.navigate([this.inboundOrderDetail], {queryParams: {id: id}}).then();
  }  // 导航到 入库单 列表页面

  // 出库
  toSalesOrderList() {
    return this.router.navigate([this.salesOrderList]).then();
  }  // 导航到 sales order list
  toSalesOrderAdd() {
    return this.router.navigate([this.salesOrderAdd]).then();
  }  // 导航到 sales order add
  toSalesOrderDetail(salesOrderId: string) {
    return this.router.navigate([this.salesOrderDetail], {queryParams: {salesOrderId: salesOrderId}}).then();
  }  // 导航到 入库单 列表页面

  toSalesListBySalesOrderId(salesOrderId: string) {
    return this.router.navigate([this.salesList], {queryParams: {salesOrderId: salesOrderId}}).then();
  }  // 导航到 入库单 列表页面

  toSalesSelectActionListBySalesOrderId(salesOrderId: string) {
    return this.router.navigate([this.salesSelectActionList], {queryParams: {salesOrderId: salesOrderId}}).then();
  }  // 导航到 入库单 列表页面


  toOutboundOrderList() {
    return this.router.navigate([this.outboundOrderList]).then();
  }  // 导航到 outboundOrderList 列表页面
  toOutboundOrderDetail(id: string) {
    return this.router.navigate([this.outboundOrderDetail], {queryParams: {id: id}}).then();
  }  // 导航到 入库单 列表页面

  toOutboundOrderListBySalesOrderId(salesOrderId: string) {
    return this.router.navigate([this.outboundOrderList], {queryParams: {salesOrderId: salesOrderId}}).then();
  }  // 导航到 outboundOrderList 列表页面

  toOutboundListByOutboundOrderId(outboundOrderId: string) {
    return this.router.navigate([this.outboundList], {
      queryParams: {
        outboundOrderId: outboundOrderId,
      }
    }).then();
  }  // 导航到 入库单 列表页面

  toOutboundDetailByOutboundId(outboundId: string) {
    return this.router.navigate([this.outboundDetail], {
      queryParams: {
        outboundId: outboundId,
      }
    }).then();
  }  // 导航到 入库单 列表页面

  toOutboundAction() {
    return this.router.navigate([this.outboundActionList]).then();
  }  // 导航到入库 action

  toOutboundActionListByOutboundOrderId(outboundOrderId: string) {
    return this.router.navigate([this.outboundActionList], {queryParams: {outboundOrderId: outboundOrderId}}).then();
  }  // 导航到入库 action

  toOutboundActionLocationListByOutboundOrderId(salesListId: string, outboundOrderId: string, purchaseId) {
    return this.router.navigate([this.outboundActionLocation], {queryParams: {salesListId: salesListId, outboundOrderId: outboundOrderId, purchaseId: purchaseId}}).then();
  }  // 导航到入库 action




  toStock() {
    return this.router.navigate([this.stock]).then();
  }  // 导航到库存页面

  // 供应商
  toSupplier() {
    return this.router.navigate([this.supplier]).then();
  }  // 导航到供应商页面
  toClient() {
    return this.router.navigate([this.client]).then();
  }  // 客户
  toReceiver() {
    return this.router.navigate([this.receiver]).then();
  }  // 收货人
  toReceiverList() {
    return this.router.navigate([this.receiverList]).then();
  }  // 收货人
  toReceiverMap() {
    return this.router.navigate([this.receiverMap]).then();
  }  // 收货人
  toWarehouseMap() {
    return this.router.navigate([this.warehouseMap]).then();
  }  // 收货人
  toInventory() {
    return this.router.navigate([this.inventory]).then();
  }  // 盘点
  toWarehouse() {
    return this.router.navigate([this.warehouse]).then();
  }  // 仓库
  toTruck() {
    return this.router.navigate([this.truck]).then();
  }  // 卡车
  toTruckOrder() {
    return this.router.navigate([this.truckOrder]).then();
  }  // 卡车
  toHelp() {
    return this.router.navigate([this.help]).then();
  }  // 帮助
  toLanguage() {
    return this.router.navigate([this.language]).then();
  }  // 语言
  toAdministrativeMap() {
    return this.router.navigate([this.administrativeMap]).then();
  }  // 语言
  toCovidMap() {
    return this.router.navigate([this.covidMap]).then();
  }  // 语言
  toCovidReport() {
    return this.router.navigate([this.covidReport]).then();
  }  // 语言

  toPandaMap() {
    return this.router.navigate([this.pandaMap]).then();
  }  // 语言
  toPandaMap2021() {
    return this.router.navigate([this.pandaMap2021]).then();
  }  // 语言
  toPandaMap2023() {
    return this.router.navigate([this.pandaMap2023]).then();
  }  // 语言

  toCovidLaoMap() {
    return this.router.navigate([this.covidLaoMap]).then();
  }  // 语言

  toBeer() {
    return this.router.navigate([this.beer]).then();
  }  // 语言


  toBack() {
    this.location.back();
  } // 返回上个页面

  // toAdminUserEdit(id: string) {return this.router.navigate([this.adminUserEdit, id.toString()]).then(); }  // 导航到后台用户编辑页面


  toSalesAddBySalesOrderId(SalesOrderId: any) {
    return this.router.navigate([this.salesAdd], {queryParams: {salesOrderId: SalesOrderId}}).then();

  }

  toSalesListAddBySalesOrderId(SalesOrderId: any) {
    return this.router.navigate([this.salesAdd], {queryParams: {salesOrderId: SalesOrderId}}).then();

  }
  toOutboundOrderAddBySalesOrderId(salesOrderId: string) {
    return this.router.navigate([this.outboundOrderAdd], {queryParams: {salesOrderId: salesOrderId}}).then();
  }  // 导航到 入库单 列表页面


}




